import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.svg";
import Coin from "../../assets/images/coin-icon-svg.svg";
import Diamond from "../../assets/images/diamond-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../Utilities/routesFb";
import { func } from "../../Utilities/logFunc";
import { getAccountProfile, getLanguage } from "../../Utilities/controller";
import JoinViaInviteCodeModal from "./JoinViaCode/JoinViaInviteCodeModal";
import ApplyPromoCodeModel from "./ApplyPromoCode/ApplyPromoCodeModel";
import SuccessPromoCodeModal from "./ApplyPromoCode/SuccessPromoCodeModal";
import TournamentConfirmationModal from "./Tournament/TournamentConfirmationModal";
import TournamentModal from "./Tournament/TournamentModal";
import WinningBreakUp from "./Tournament/WinningBreakUp";
import JoinModal from "./Tournament/JoinModal";
import ReferAFriend from "./ReferAFriend/ReferAFriend";
import Logout from "../Auth/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GamerjiPoints from "./GamerjiPoints/GamerjiPoints";
import Profile from "../../assets/images/profile-icon-svg.svg";
import JVC from "../../assets/images/link-icon-svg.svg";
import PromoCode from "../../assets/images/applyPromocode-icon-svg.svg";
import Friend from "../../assets/images/referFriend-icon-svg.svg";
import Points from "../../assets/images/list-icon-svg.svg";
import Legality from "../../assets/images/legality-icon-svg.svg";
import Favorite from "../../assets/images/favouriteGame-icon-svg.svg";
import LogoutIcon from "../../assets/images/logout-icon-svg.svg";
import { url as URL } from "../../Utilities/url";

import FavoriteGamesModal from "./FavoriteGames/FavoriteGamesModal";
import DailyLoginRewardModal from "./DailyLoginReward/DailyLoginRewardModal";
import EditProfile from "../Profile/EditProfile";
import MyProfile from "../Profile/MyProfile";
import { useProfileDataContext } from "./ProfileContext";
import { logout } from "../../Utilities/logout";
import i18next from "i18next";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import ChangePin from "./ChangePIn/ChangePin";
import ChangePinIcon from "../../assets/images/changePinIcon.svg";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const Navbar = ({ activeNav, isActiveMenu, seActiveMenu }) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const navsv = [
    "/home",
    "/home/world-of-esports",
    "/home/coin-store",
    "/home/reward-store",
    "/home/leader-board",
    "/home/customer-care",
    "/home/getPremium",
  ];
  const navName = [
    "sidebar.all_games",
    "sidebar.world_of_esports",
    "sidebar.diamond_store",
    "sidebar.reward_store",
    "sidebar.leaderboard",
    "sidebar.customer_care",
    "sidebar.explore_premium",
  ];

  const [url, setUrl] = useState(window.location.href.split("/").length - 3);
  // const [profileData, setProfileData] = useState(null);
  const [showJVC, setShowJVC] = useState(false);
  const [showAPC, setShowAPC] = useState(false);
  const [showSPC, setShowSPC] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showTournamentConfirmationModal, setShowTournamentConfirmationModal] =
    useState(false);
  const [showReferFriend, setShowReferFriend] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showGamerjiPointsModal, setShowGamerjiPointsMOdal] = useState(false);
  const [showFavoriteGame, setShowFavoriteGame] = useState(false);
  const [showMyProfile, setShowMyProfile] = useState(false);
  const [showDLR, setShowDLR] = useState(false);
  const [active, setActive] = useState(null);
  const { profileData, updateProfileData } = useProfileDataContext();
  // var user_data = JSON.parse(localStorage.getItem("profile"))?.item;

  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("d11Lang")  || Cookies.get("d11Lang") ||  "English"
  );
  const [loader, setLoader] = useState(false);

  const [coinId, setCoinId] = useState(
    profileData?.item?.wallet?.findIndex(
      (obj) => obj.currencyData?.code == "DC"
    )
  );
  const [diamondId, setDiamondId] = useState(
    profileData?.item?.wallet?.findIndex(
      (obj) => obj.currencyData?.code == "D11"
    )
  );
  const [showChangePin, setShowChangePin] = useState(false);

  const currentLang = localStorage.getItem("d11LangCode") || Cookies.get("d11LangCode")  ||  "en"

  const fetchLanguage = async () => {
    const body = {
      companyId: process.env.REACT_APP_COMPANY_ID,
    };
    try {
      const res = await getLanguage(body);
      // console.log("fetchLanguage",res?.data?.data)
      setLanguages(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLanguage();
    const linkTag = document.getElementById('bootstrap_css');
    document.documentElement.dir =
      currentLang.toLowerCase() == "ar" ? "rtl" : "ltr";
    if (currentLang.toLowerCase() == "ar") {
      document.documentElement.setAttribute('lang', 'ar');
      linkTag?.setAttribute(
        'href',
        `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`
      );
    }
         
    document.title = t("title");
  }, [currentLang]);

  const handleApplyPromoCode = () => {
    setShowAPC(true);
  };

  const getTotalCoin = () => {
    let index = profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "D11");

    if (index > -1) {
      return profileData?.item?.wallet[index].winningAmount ? profileData?.item?.wallet[index].winningAmount : 0;
    }
    return 0;
  };

  // const getAccountProfileDetails = async () => {
  //   try {
  //     const res = await getAccountProfile();
  //     if (res?.data) {
  //       setProfileData(res?.data);
  //       localStorage.setItem("profile", JSON.stringify(res?.data));
  //       localStorage.setItem(
  //         "currentD11",
  //         getTotalCoin(res?.data?.item?.wallet)
  //       );
  //     }

  //     func("profile", res?.data?.item);
  //   } catch (error) {
  //     func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
  //   }
  // };

  const handlerJVC = () => {
    func("JVC", "called");
    setShowJVC(true);
  };

  const handlerJoin = () => {
    func("clikced");
    setShowJoinModal(true);
  };

  const handlerJoinNow = () => {
    func("handlerjoin");
    setShowJoinModal(false);
    setShowTournamentModal(true);
  };

  const handlerWinning = () => {
    func("clicked");
    setShowWinningModal((prev) => true);
  };

  useEffect(() => {
    func("active nav", activeNav);
    let index = null;
    navsv.forEach((element, ind) => {
      if (element == activeNav) index = ind;
    });
    func("index 126", index);
    // getAccountProfileDetails();
  }, []);

  useEffect(() => {
    // alert(showJVC);
  }, [showJVC]);

  const getTotalDiamond = () => {
    // let index =
    //   profileData?.item &&
    //   profileData?.item?.wallet &&
    //   profileData?.item?.wallet.findIndex(
    //     (obj) => obj.currencyData?.code == "diamond"
    //   );
    // // let index = accountResponse.wallet.findIndex(obj => obj.currencyData.name == 'diamond')
    // if (index > -1) {
    //   return profileData?.item?.wallet[index].winningAmount != null
    //     ? profileData?.item?.wallet[index].winningAmount
    //     : 0;
    // }
    // return 0;
    let index = profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "DC");

    if (index > -1) {
    
      return profileData?.item?.wallet[index].winningAmount ? profileData?.item?.wallet[index].winningAmount : 0;
    }
    return 0;
  };
  
  return (
    <>
      <ToastContainer   position="top-center" autoClose={2000} />
      <nav class="navbar navbar-top fixed-top navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="navbar-logo">
            <button
              class={`navbar-toggler mainMenuToggler ${isActiveMenu? "collapsed": ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={!isActiveMenu? "false": "true"}
              aria-label="Toggle navigation"
              onClick={()=> seActiveMenu(!isActiveMenu)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
            <Link class="navbar-brand" to="/home">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div class="navRight d-flex align-items-center justify-content-sm-between justify-content-end">
            <nav aria-label="breadcrumb" className="d-none d-sm-block">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  {/* {console.log(window.location.pathname.split("/"))} */}
                  <Link to={navsv[activeNav]}>{t(navName[activeNav])}</Link>
                </li>
                {/* <li class="breadcrumb-item"></li> */}
                {window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ] === "my-profile" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/my-profile">
                        {/* {t("header.my_profile")} */}
                        
                        {
                              t(
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])
                            }
                      </Link>
                    </li>
                  </>
                ) : window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 2
                  ] === "user-profile" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link>{t("header.user_profile")}</Link>
                    </li>
                  </>
                ) : window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                  ] === "free-games" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/free-games">
                        {t("header.free_games")}
                      </Link>
                    </li>
                  </>
                ) : activeNav == 0 &&
                  window.location.pathname.split("/").length > 2 ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/tournaments">
                        {localStorage.getItem("activeGame")}
                      </Link>
                    </li>
                    {window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "tournaments" ? (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {/* {
                          window.location.pathname.split("/")[
                            window.location.pathname.split("/").length - 1
                          ]
                        } */}
                        {/* </Link> */}
                      </li>
                    ) : window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "private-contest" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="/home/private-contest">
                            {/* {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[1]
                            } */}
                            {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])}
                          </Link>
                        </li>
                      </>
                    ) : // window.location.pathname.split("/")[
                    //   window.location.pathname.split("/").length - 2
                    // ] == "private-contest" ?
                    //  (
                    //   <>
                    //     <li
                    //       class="breadcrumb-item"
                    //       aria-current="page"
                    //       style={{ textTransform: "capitalize" }}
                    //     >
                    //       <Link to="/home/private-contest">
                    //         {
                    //           window.location.pathname
                    //             .split("/")
                    //           [
                    //             window.location.pathname.split("/").length - 2
                    //           ].split("-")[0]
                    //         }{" "}
                    //         {
                    //           window.location.pathname
                    //             .split("/")
                    //           [
                    //             window.location.pathname.split("/").length - 2
                    //           ].split("-")[1]
                    //         }
                    //       </Link>
                    //     </li>
                    //     <li
                    //       class="breadcrumb-item"
                    //       aria-current="page"
                    //       style={{ textTransform: "capitalize" }}
                    //     >
                    //       {
                    //         window.location.pathname
                    //           .split("/")
                    //         [
                    //           window.location.pathname.split("/").length - 1
                    //         ].split("-")[0]
                    //       }{" "}
                    //       {
                    //         window.location.pathname
                    //           .split("/")
                    //         [
                    //           window.location.pathname.split("/").length - 1
                    //         ].split("-")[1]
                    //       }
                    //     </li>
                    //   </>
                    // )
                    // :
                    window.location.pathname.split("/").length > 3 &&
                      window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 2
                      ] == "manage-contest" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="/home/private-contest">
                            {/* {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 3
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 3
                                ].split("-")[1]
                            } */}
                            {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 3
                                ])}
                          </Link>
                        </li>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ].split("-")[0]
                          }{" "}
                          {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ].split("-")[1]
                          } */}
                            {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 2
                                ])}
                        </li>
                      </>
                    ) :
                    
                    window.location.pathname.split("/").length > 3 &&
                    window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "create-contest" ? (
                    <>
                   {/* { console.log(  window.location.pathname.split("/"))} */}
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        <Link to="/home/private-contest">
                          {/* {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ].split("-")[0]
                          }{" "}
                          {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ].split("-")[1]
                          } */}
                        
                          {t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ])}
                        </Link>
                      </li>
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[1]
                        } */}
                         {/* {console.log(t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 1
                              ]))} */}
                          {t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 1
                              ])}
                      </li>
                    </>
                  ) :

                  window.location.pathname.split("/").length > 3 &&
                  window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 2
                  ] == "edit-contest" ? (
                  <>
                  {/* {console.log(window.location.pathname.split("/"), window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 2
                  ])} */}
                    <li
                      class="breadcrumb-item"
                      aria-current="page"
                      style={{ textTransform: "capitalize" }}
                    >
                      <Link to="/home/private-contest">
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 3
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 3
                            ].split("-")[1]
                        } */}
                        {/* {console.log(t(window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 3
                            ]))} */}
                        {t(window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 3
                            ])}
                      </Link>
                    </li>
                    <li
                      class="breadcrumb-item"
                      aria-current="page"
                      style={{ textTransform: "capitalize" }}
                    >
                      {/* {
                        window.location.pathname
                          .split("/")
                          [
                            window.location.pathname.split("/").length - 2
                          ].split("-")[0]
                      }{" "}
                      {
                        window.location.pathname
                          .split("/")
                          [
                            window.location.pathname.split("/").length - 2
                          ].split("-")[1]
                      } */}
                        {t(window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ])}
                    </li>
                  </>
                ) :

                    window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "my-contest" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link >
                            {/* {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "} */}
                            {
                              t(
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])
                            }
                          </Link>
                        </li>
                      </>
                    ) : window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "payment-status" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="">
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[1]
                            }
                          </Link>
                        </li>
                      </>
                    ) : window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "payment-status-subscription" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="">
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[1]
                            }
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[2]
                            }
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li class="breadcrumb-item" aria-current="page">
                        {window.location.pathname.split("/")[
                          window.location.pathname.split("/").length - 2
                        ] == "tournament-details"
                          ? t('tournament-details')
                          : window.location.pathname.split("/")[
                              window.location.pathname.split("/").length - 2
                            ] == "contest-details"
                          ? t('contest-details')
                          : window.location.pathname.split("/")[
                              window.location.pathname.split("/").length - 2
                            ] == "scrim-details"
                          ? t('contest-details')
                          : ""}
                      </li>
                    )}
                  </>
                ) : activeNav == 1 &&
                  window.location.pathname.split("/").length > 3 ? (
                  <>
                    {
                      // window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] == 'tournaments'
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[1]
                        } */}
                        {console.log(t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ]))}
                        {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])}
                        {/* </Link> */}
                      </li>
                    }
                  </>
                ) : activeNav == 5 &&
                  window.location.pathname.split("/").length > 3 ? (
                  <>
                    {window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "raise-a-complaint" ? (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[1]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[2]
                        } */}
                        {t( window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ])}
                        {/* </Link> */}
                      </li>
                    ) : (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[1]
                        }{" "}
                      </li>
                    )}
                  </>
                ) : activeNav == 6 &&
                  window.location.pathname.split("/").length > 3 ? (
                  <>
                    {window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "get-premium" ? (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[1]
                        }{" "}
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* <li class="breadcrumb-item" aria-current="page">
                  <a href="#">{gameName}</a>
                </li> */}
                {/* <li class="breadcrumb-item active" aria-current="page">
                Tournaments
              </li> */}
              </ol>
            </nav>
            <div class="rightNav">
              <div class="badge">
                <span class="badge-icon" style={{caretColor: 'transparent'}}>
                  {/* {console.log(url.imageUrl)} */}
                  <img src={URL.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "D11")]?.currencyData?.img?.default} alt="" className="icon-30"/>
                </span>
                {/* {localStorage.getItem("currentD11")}{" "} */}
                {getTotalCoin()}
              </div>
              <div class="badge">
                <span class="badge-icon" style={{caretColor: 'transparent'}}>
                  <img src={URL.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "DC")]?.currencyData?.img?.default} alt="" className="icon-30"/>
                </span>
                {/* {localStorage.getItem("currentD11")}{" "} */}
                {getTotalDiamond()}
              </div>
              {/* <div class="badge">
                <span class="badge-icon">
                  <img src={Diamond} alt="" />
                </span>
                {/* {localStorage.getItem("currentD11")}{" "} */}
              {/* {getTotalDiamond()} */}

              {/* {getTotalDiamond()} */}
              {/* </div> */}
              <div class="dropdown customDropdown ms-xl-5 ms-3">
                <span
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedLanguage}
                </span>

                <ul class="dropdown-menu">
                  {languages?.map((ele,ind) => (
                    <li key={ind}>
                      <span
                        class="dropdown-item"
                        onClick={(e) => {
                          setLoader(true);
                          i18next.changeLanguage(ele?.code?.toLowerCase());
                          localStorage.setItem(
                            "d11Lang",
                            ele.name
                          );
                          localStorage.setItem('d11LangCode',ele?.code?.toLowerCase())
                          Cookies.set('d11Lang',ele.name)
                          Cookies.set('d11LangCode',ele?.code?.toLowerCase())
                          eventTracking(events.CHANGE_LANGUAGE,{
                            MG_language: ele.name
                          });
                          // const head = document.head;
                          // const link = document.createElement("link");
                          // link.type = "text/css";
                          // link.rel = "stylesheet";

                          // // Set the href based on the language
                          // link.href =
                          //   currentLang === "ar"
                          //     ? `"%PUBLIC_URL%/assets/css/bootstrap.rtl.min.css`
                          //     : `"%PUBLIC_URL%/assets/css/bootstrap.min.css`;

                          // // Clear existing stylesheets, if any
                          // const existingLinks = head.querySelectorAll(
                          //   'link[data-type="bootstrap"]'
                          // );
                          // existingLinks.forEach((existingLink) =>
                          //   existingLink.remove()
                          // );

                          // // Add the new stylesheet link
                          // link.dataset.type = "bootstrap";
                          // head.appendChild(link);
                          // console.log(`"%PUBLIC_URL%/assets/css/bootstrap.min.css`, `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`)
                          window.location.href = window.location.pathname
                          setLoader(false);
                        }}
                      >
                        {ele.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="dropdown customDropdown userProfileDropdown ms-xl-5 ms-sm-4 ms-2">
                <span
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="userIcon">
                    <img
                      src={
                        URL.imageUrl +
                        profileData?.item?.level?.level?.featuredImage?.default
                      }
                      alt=""
                    />
                  </span>
                  <span className="profileName">
                    <span style={{ caretColor: "transparent" }}>
                      {profileData?.item?.gamerjiName}
                    </span>
                  </span>
                </span>

                <ul class="dropdown-menu">
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => navigate(routes.myProfile)}
                    >
                      <span class="icon ">
                        <img class="icon-16 me-2" src={Profile} alt="" />
                      </span>
                      {t("right_hand_drop_down.my_profile")}
                    </span>
                  </li>
                  <li>
                    <span class="dropdown-item cursor" onClick={handlerJVC}>
                      <span class="icon">
                        <img class="icon-16 me-2" src={JVC} alt="" />
                      </span>
                      {t("right_hand_drop_down.join_via_invite_code")}
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => setShowDLR(true)}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={JVC} alt="" />
                      </span>
                      {t("right_hand_drop_down.daily_login_rewards")}
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => setShowReferFriend(true)}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={Friend} alt="" />
                      </span>
                      {t("right_hand_drop_down.refer_a_friend")}
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item cursor"
                      onClick={handleApplyPromoCode}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={PromoCode} alt="" />
                      </span>
                      {t("right_hand_drop_down.apply_promo_code")}
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => setShowGamerjiPointsMOdal(true)}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={Points} alt="" />
                      </span>
                      {t('right_hand_drop_down.mobily_points')}
                    </span>
                  </li>

                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => navigate("/home/legality")}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={Legality} alt="" />
                      </span>
                      {t("right_hand_drop_down.legality")}
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => setShowChangePin(true)}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={ChangePinIcon} alt="" />
                      </span>
                      {t("right_hand_drop_down.change_pin")}
                    </span>
                  </li>
                

                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => setShowFavoriteGame(true)}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={Favorite} alt="" />
                      </span>
                      {t("right_hand_drop_down.favorite_game")}
                    </span>
                  </li>
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => setLogoutModal(true)}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={LogoutIcon} alt="" />
                      </span>
                      {t("right_hand_drop_down.logout")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {showJVC && (
        <JoinViaInviteCodeModal showJVC={showJVC} setShowJVC={setShowJVC} />
      )}
      {showAPC && (
        <ApplyPromoCodeModel
          showAPC={showAPC}
          setShowAPC={setShowAPC}
          setShowSPC={setShowSPC}
        />
      )}
      {showSPC && (
        <SuccessPromoCodeModal showSPC={showSPC} setShowSPC={setShowSPC} />
      )}
      {showJoinModal && (
        <JoinModal
          showJoinModal={showJoinModal}
          setShowJoinModal={setShowJoinModal}
          handlerWinning={handlerWinning}
          handlerJoinNow={handlerJoinNow}
        />
      )}
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
        />
      )}
      {showTournamentModal && (
        <TournamentModal
          showTournamentModal={showTournamentModal}
          setShowTournamentModal={setShowTournamentModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
        />
      )}

      {showTournamentConfirmationModal && (
        <TournamentConfirmationModal
          showTournamentConfirmationModal={showTournamentConfirmationModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
        />
      )}
      {showReferFriend && (
        <ReferAFriend
          showReferFriend={showReferFriend}
          setShowReferFriend={setShowReferFriend}
        />
      )}
      {logoutModal && (
        <Logout
          logoutModal={logoutModal}
          setLogoutModal={setLogoutModal}
          logout={logout}
        />
      )}
      {showGamerjiPointsModal && (
        <GamerjiPoints
          showGamerjiPointsModal={showGamerjiPointsModal}
          setShowGamerjiPointsMOdal={setShowGamerjiPointsMOdal}
        />
      )}
      {showFavoriteGame && (
        <FavoriteGamesModal
          showFavoriteGame={showFavoriteGame}
          setShowFavoriteGame={setShowFavoriteGame}
        />
      )}
      {/* {
        showMyProfile && <MyProfile showMyProfile={showMyProfile} setShowMyProfile={setShowMyProfile} />
      } */}
      {showDLR && (
        <DailyLoginRewardModal showDLR={showDLR} setShowDLR={setShowDLR} />
      )}
      {showChangePin && (
        <ChangePin
          showChangePin={showChangePin}
          setShowChangePin={setShowChangePin}
        />
      )}
    </>
  );
};

export default Navbar;
