export const events = {
  MENU: {
    All_GAMES: "all_games_menu",
    WOE: "world_of_esprots_menu",
    DIAMOND_STORE: "diamond_store_menu",
    REWARD_STORE: "reward_store_menu",
    LEADERBOARD: "leaderboard_menu",
    CRM: "crm_menu",
    PREMIUM: "premium_menu",
  },
  LOGIN: {
    LOGIN: "af_login",
    RESET_PIN: "af_reset_pin",
  },
  CONTEST_LIST: {
    JOIN_NOW: "af_contests_join_now",
    JOIN_CONTEST: "af_contests_joined",
  },
  LOGOUT: "af_logout",
  CHANGE_PIN: "af_change_pin",
  DAILY_LOGIN_REWARD: "af_daily_login_reward_claim",
  UNSUBSCRIBE: "af_unscbscribe",
  COUNTRY_CODE: "af_country_code",
  country_code:"af_country_code",
  APPLY_SIGNUP_CODE: "af_apply_signup_code",
  REMOVE_SIGNUP_CODE: "af_remove_signup_code",
  REGISTER: "af_register",
  TERM_N_CONDITION: "af_term_condition_signup",
  PRIVACY_POLICY: "af_privacy_policy_signup",
  LOGIN_TAB: "af_login_tab",
  REGISTER_TAB: "af_register_tab",
  SIGNUP_RESEND_OTP: "af_signup_resend_otp",
  SIGNUP_OTP_VERIFIED: "af_signup_otp_verified",
  AVATAR_SELECT: "af_avatar_select",
  SIGNUP_COMPLATE: "af_create_profile",
  BANNER: "af_banners",
  GAME_ALL_GAMES: "af_game_allgames",
  FEATURE_TOURNAMENTS: "af_featured_tournament",
  TOURNAMENT_TIMING_SELECTION: "af_tournament_time_selection",
  TOURNAMENT_TIMING_SELECTION_NEXT: "af_tournament_timeselection_next",
  JOIN_TOURNAMENT: "af_join_tournament",
  BUY_PACKS: "af_buy_packs",
  BUY_BUNDLE: "af_buy_bundle",
  BUY_AVATAR: "af_buy_avatar",
  LEADERBOARD_TODAY_TAB: "af_leaderboard_today_tab",
  SUBSCRIPTION_PLAN: "af_subscription_plan",
  PURCHASE: "af_purchase",
  PAYMENT_STATUS_POPUP: "af_payment_status_popup",
  FREE_TOURNAMENT_NON_PREMIUM: "af_tourament_non_premium",
  FREE_CONTEST_NON_PREMIUM: "af_contest_non_premium",
  REQUEST_CALL_BACK: "af_request_callback",
  SELECT_CALL_BACK_TIME: "af_select_callback_time",
  SCHEDULE_CALL: "af_schedule_call",
  CHANGE_LANGUAGE:"af_change_language"
};
