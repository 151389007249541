import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { howToJoinGame } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { toast } from "react-toastify";
import { logout } from "../../../Utilities/logout";
import { useTranslation } from "react-i18next";

const HowToJoin = ({ isHowToJoinOpen, setIsHowToJoinOpen }) => {
  const [show, setShow] = useState(isHowToJoinOpen);
  const [contentPairs, setContentPairs] = useState([]);
  const { t } = useTranslation();

  const fetchDetails = async () => {
    // const filter = { game: localStorage.getItem("tournamentIdGame") }; // Define the filter object
    let gameID=localStorage.getItem("tournamentIdGame")
    console.log("gameID1",gameID)
    if(gameID==''||gameID==null){
       gameID=localStorage.getItem("activeGameId")
    }
    console.log("gameID2",gameID)
    const body = {
      limit:1,
      sort:'asc',
      filter: { 
        game: gameID.toString(), 
        q: "how to" 
      }
    }
    try {
      const res = await howToJoinGame(body); // Pass the filter object as an argument

      const dataList = res?.data?.list?.[0]?.content || []; // Access the content array

      // const dataList = res?.data?.list?.[res?.data?.list?.length-1]?.content || []; // Access the content array
      // // Iterate through the content and create pairs of text and image
      const pairs = [];
      let textContent = null,
        imageContest = null;

      dataList?.forEach((item, index) => {
        const type = item?.type;
        const content = item?.content;

        if (type === "text") {
          textContent = content;
          pairs.push({ text: textContent });
        } else if (type === "image") {
          imageContest = content;
          pairs.push({ image: imageContest });
        }

        // if (textContent && imageContest) {
        //   pairs.push({ text: textContent, image: imageContest });
        //   textContent = null;
        //   imageContest = null;
        // }
      });

      // Update state with the pairs
      setContentPairs(pairs);
    } catch (error) {
      // Handle any errors that may occur during the API request
      if (
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error.response?.data?.errors[0]?.msg);
    }
  };

  const handleClose = () => {
    setIsHowToJoinOpen((prev) => false);
    setShow(false);
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      {/* <Modal.Header closeButton>
                     <Modal.Title>Modal title</Modal.Title>
                   </Modal.Header> */}
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-sm-9 col-12">
            <div className="tab-content auth-content">
              <form className="row justify-content-center">
                <h2 className="verification_heading text-center">
                  {t("contest.how_to_join")}
                </h2>

                <div className="grid-container pt-4">
                  {contentPairs.map((pair, index) => (
                    pair.text ?
                      <p className="fs-6 mt-3">

                        {/* {pair.replace(/^\s\t$/, "")} */}
                        {pair.text}
                      </p>
                      // ))}
                      :
                      <div
                        className="mt-3"
                        style={{
                          height: "15rem",
                        }}
                      >
                        <img
                          src={pair.image}
                          className="rounded10 w-100 h-100"
                          alt=""
                        />
                      </div>
                  ))}

                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowToJoin;
