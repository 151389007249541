import React, { useState } from "react";
import Load from "../../assets/images/loader.gif";
import { Modal } from "react-bootstrap";
const Loader = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  return (
    <Modal
      show={show}
      className="loader"
      centered
    >
      <Modal.Body
      style={{
        content: {
          backgroundColor: "red !important",
        },
      }}
      >
        <div className="loader">
          <img src={Load} />{" "}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
