import React from 'react'
import Section1 from './images/section1.png'
import Section2 from './images/section2.png'
import Section3 from './images/section3.png'

const CardSection = () => {
  return (
    <div class="container">
      <section class="section-connect">
        <div class="row flex-column">
          <div class="col my-4">
            <h1 class="heading-primary text-center">how it works</h1>
          </div>
          <div class="col mb-5">
            <p class="text-white text-center my-0">With the most popular esports games and daily tournaments, D11 is the gaming destination in the Middle East.</p>
            <p class="text-white text-center">Join now to connect and compete with fellow gamers, win prizes, get rewarded and level up.</p>
          </div>
        </div>
        <div class="row connect-card row-cols-1 row-cols-md-3 mb-5 g-4">
          <div class="col">
            <div class="card icon-400">
              <img src={Section2} class="card-img-top " alt="..." />
              <div class="card-body">
                <h3 class="title_secondary text-white blue-line">Connect</h3>
                <p class="text-white mt-4">Play in our daily and weekly tournaments with friends and other gamers across the region. Or challenge your favourite influencers, YouTubers and content creators across different games.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card icon-400">
              <img src={Section1} class="card-img-top" alt="..." />
              <div class="card-body">
                <h3 class="text-white title_secondary blue-line">Compete</h3>
                <p class="text-white mt-4">Compete and win rewards and real world prizes in our competitive esports tournaments designed for everyone.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card icon-400">
              <img src={Section3} class="card-img-top" alt="..." />
              <div class="card-body">
                <h3 class="title_secondary text-white blue-line">Ka-Ching!</h3>
                <p class="text-white mt-4">Earn D11 Gold and convert them to cash and product prizes by competing in our tournaments.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CardSection