import React from 'react'
import { Link } from 'react-router-dom'
// import { HashLink } from 'react-router-hash-link'
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <div class="container">
    <footer class="footer d-flex justify-content-center">
       <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
             <Link to="/" class="footer-logo mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="75" height="29" viewbox="0 0 75 29" fill="none">
                   <g clip-path="url(#clip0_3879_96871)">
                      <path d="M48.5431 16.4006C48.197 17.4955 47.483 18.0444 46.4013 18.0472C45.5147 18.0472 45.1092 17.6766 45.1847 16.9353C45.2057 16.7534 45.2456 16.5742 45.3035 16.4006L46.8101 11.619C47.1562 10.524 47.8688 9.97656 48.9477 9.97656C49.838 9.97656 50.2449 10.3472 50.1685 11.0885C50.1471 11.2688 50.1078 11.4465 50.0511 11.619L49.7187 12.6701H48.5869L48.918 11.619C48.9362 11.5619 48.949 11.5031 48.9562 11.4436C48.9802 11.2087 48.86 11.0913 48.594 11.0885H48.5855C48.2616 11.0885 48.0451 11.2653 47.9334 11.619L46.4267 16.4006C46.4083 16.4591 46.3955 16.5193 46.3885 16.5802C46.3645 16.8136 46.4847 16.9311 46.7507 16.9311C47.0808 16.9311 47.3005 16.7542 47.4099 16.4006L47.9899 14.5615H47.4241L47.7735 13.4453H49.4711L48.5431 16.4006Z" fill="white"></path>
                      <path d="M53.8564 15.1438L53.3485 17.9165H52.2026L52.511 16.267H51.3538L50.6083 17.9236H49.4766L50.713 15.1622L53.108 10.0977H54.6542L53.8564 15.1438ZM52.7176 15.1438L53.3103 11.89L51.856 15.1438H52.7176Z" fill="white"></path>
                      <path d="M58.0692 15.4536L61.0711 10.0977H62.2028L59.7385 17.9165H58.6577L60.0412 13.5311L57.6929 17.9165H56.9276L57.318 13.5311L55.9359 17.9165H54.8438L57.3081 10.0977H58.4398L58.0692 15.4536Z" fill="white"></path>
                      <path d="M64.0712 10.0977H65.203L62.7372 17.9165H61.6055L64.0712 10.0977Z" fill="white"></path>
                      <path d="M67.0737 10.0977H68.2055L67.8433 14.6331L69.2735 10.0977H70.3105L67.849 17.9165H66.7172L67.0723 13.5311L65.6888 17.9165H64.6094L67.0737 10.0977Z" fill="white"></path>
                      <path d="M73.3399 16.4006C72.9938 17.4955 72.2799 18.0444 71.1981 18.0472C70.3116 18.0472 69.9061 17.6766 69.9815 16.9353C70.0026 16.7534 70.0424 16.5742 70.1004 16.4006L71.6084 11.619C71.9555 10.524 72.668 9.97656 73.746 9.97656C74.6363 9.97656 75.0432 10.3472 74.9668 11.0885C74.9456 11.2689 74.9058 11.4467 74.848 11.619L74.517 12.6701H73.3852L73.7163 11.619C73.7345 11.5619 73.7473 11.5031 73.7544 11.4436C73.7785 11.2087 73.6568 11.0913 73.3923 11.0885H73.3838C73.0603 11.0885 72.8429 11.2653 72.7316 11.619L71.2236 16.4006C71.2052 16.4591 71.1924 16.5193 71.1854 16.5802C71.1614 16.8136 71.283 16.9311 71.5476 16.9311C71.8777 16.9311 72.0979 16.7542 72.2082 16.4006L72.7868 14.5615H72.221L72.5732 13.451H74.2708L73.3399 16.4006Z" fill="white"></path>
                      <path d="M36.4081 9.88943H37.9147L34.1758 21.7288H38.7183L43.3357 6.38672C43.3357 6.38672 38.0619 6.41784 36.4039 9.88943" fill="white"></path>
                      <path d="M25.5022 22.7182L29.2411 10.8789H27.5223L28.1971 9.46419C29.1874 7.38604 31.2033 6.37031 32.9405 5.87376C32.833 5.49888 32.5585 4.61471 32.1723 4.00074C31.4358 2.71246 30.3149 1.72219 28.8097 1.02995C27.3045 0.337702 25.1669 -0.00558984 22.397 6.88216e-05H8.34653L3.41925 16.6167C3.41925 16.6167 12.8282 17.1571 14.3122 11.9314C15.6717 7.13847 15.9433 6.35192 15.9433 6.35192H18.303C20.755 6.35192 22.3494 6.90081 23.0859 7.99859C23.8225 9.09637 23.7565 11.1307 22.8879 14.1015C22.2277 16.3461 21.5161 17.9696 20.7532 18.9722C19.9902 19.9747 19.1305 20.6768 18.1742 21.0786C17.2207 21.4804 15.7556 21.6812 13.7788 21.6812H1.86594L0 28.0359L2.32713 28.0501H14.1919C15.8773 28.0501 17.8319 27.7954 20.0557 27.2862C21.6807 26.9202 23.3184 26.1771 24.9689 25.0566C25.9054 24.4229 27.2493 23.1582 27.7077 22.7182H25.5022Z" fill="white"></path>
                      <path d="M29.0896 9.88943H30.5919L26.8516 21.7288H31.3941L36.0186 6.38672C36.0186 6.38672 30.7447 6.41784 29.0867 9.88943" fill="white"></path>
                   </g>
                   <defs>
                      <clippath id="clip0_3879_96871">
                         <rect width="74.9745" height="28.05" fill="white"></rect>
                      </clippath>
                   </defs>
                </svg>
             </Link>
             <h6 class="mb-4">Where gamers of all skill levels can play, compete, earn, learn, achieve and engage with the gaming community in the region.</h6>
             <h6 class="mb-4">With the most popular esports games and daily tournaments, D11 is the gaming destination in the Middle East. Join now to connect and compete with fellow gamers, win prizes, get rewarded and level up.</h6>
             <h6 class="mb-4">D11 – Let’s Play!</h6>
             <h6 class="mb-4">&nbsp;© 2022 D11 GAMING & ESPORTS COMPANY W.L.L</h6>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
             <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                   <div class="">
                      <h4 class="title_secondary resposive-text mb-4">Explore D11</h4>
                      <address class="">
                         <ul class="footer-nav">
                            <li>
                               <Link to={`/login`} class="footer-link" href="#">Create account</Link>
                            </li>
                            <li>
                               <Link to={`/about`} class="footer-link" href="#">About D11</Link>
                            </li>
                            {/* <li>
                               <Link to={`/contact`} class="footer-link" href="#">Contact Us</Link>
                            </li> */}
                            {/* <li>
                               <Link to={`/`} class="footer-link" href="#">D11 Wiki</Link>
                            </li> */}
                            <li>
                               <Link to={`/login`} class="footer-link" href="#">Register</Link>
                            </li>
                            <li>
                               <Link to={`/login`} class="footer-link" href="#">Login</Link>
                            </li>
                            <li>
                               <Link to={`/policy`} class="footer-link" href="#">Privacy Policy</Link>
                            </li>
                            <li>
                               <Link to={`/termsAndCondition`} class="footer-link" >Terms and Conditions</Link>
                            </li>
                         </ul>
                      </address>
                   </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                   <nav class="">
                      <h4 class="title_secondary resposive-text mb-4">Connect</h4>
                      <ul class="footer-nav mb-4">
                         <li>
                            {/* <Link to={'/contact/#partners1'} class="footer-link" >Partners</Link> */}
                            <HashLink class="footer-link" smooth to='/contact/#partners1' > Partners </HashLink>
                         </li>
                         <li>
                            <Link to={'/contact/#contact1'} class="footer-link" >Contact Us</Link>
                         </li>
                      </ul>
                   </nav>
                   <h4 class="title_secondary resposive-text mb-4">Follow D11</h4>
                   <ul class="social-links">
                   <Link to={`https://www.facebook.com/d11gaming`} target='_blank' style={{color:"#fff", textDecoration:'none'}}><li><i class="fa-brands fa-facebook-f"></i></li></Link>
                      <Link to={`https://www.tiktok.com/@d11.gg`} target='_blank' style={{color:"#fff", textDecoration:'none'}}><li><i class="fa-brands fa-tiktok"></i></li></Link>
                      <Link to={`https://www.instagram.com/d11.gg/`} target='_blank' style={{color:"#fff", textDecoration:'none'}}><li><i class="fa-brands fa-instagram"></i></li></Link>
                     <Link to={`https://discord.gg/3gBbpyqKeD`} target='_blank' style={{color:"#fff", textDecoration:'none'}}> <li><i class="fa-brands fa-discord"></i></li></Link>
                   </ul>
                </div>
             </div>
          </div>
       </div>
    </footer>
 </div>
  )
}

export default Footer