import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import { getContestByCode, getHtml5GameUpdate } from "../../../Utilities/controller";
import moment from "moment";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";
const FreeGamesModal = ({
    selectedGame,
    selectedSubGame,
    showGameModal,
    setShowGameModal,
    joinId
}) => {
    const [show, setShow] = useState(showGameModal);
  let premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
  const { t } = useTranslation()
  const handleClose = async () => {
    var data = {
        htmlGame: selectedSubGame._id,
        logId: joinId,
        status: "end"
    }
    var response = await getHtml5GameUpdate(data);
    setShowGameModal((prev) => false);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="tournamentModal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class={`row justify-content-center`}>
          <div class="col-11 px-lg-4">
            <div class="title">
              <h3 class={"text-center bodyBoldFont mb-4 pb-3"}>
               {selectedGame?.name}
              </h3>
            </div>
            <div class=" text-center">
              <div class="d-flex justify-content-between fw-normal">
                <h4 class={`mb-1 fw-normal`}>
                <span class="titleIcon me-3">
                    <img src="/assets/images/game-icon-svg.svg" className="h-50" alt="" />
                </span>
                 {selectedSubGame?.name}
                </h4>
                <h4 class={`mb-3 fw-normal`}>
                  <span>
                  </span>
                  <span>
                    {" "}
                  </span>
                </h4>
              </div>
              <div>
                  <div className="col-12 d11-freeGame-rectangle p-3">
                    <iframe
                      className="h-100 w-100"
                      src={selectedSubGame?.url}
                    ></iframe>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default FreeGamesModal;