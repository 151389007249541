import React, { useEffect,useState } from 'react'
import BaseModalSubscription from './BaseModalSubscription'
import { Link, useNavigate } from 'react-router-dom'
import { useProfileDataContext } from '../HomePage/ProfileContext'
import { Trans, useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const ShowFreeContestModal = ({showPremiumPopupContest,setShowPremiumPopupContest,title,setShowJVC,contest, tournaments}) => {
  const {t} = useTranslation()
  const { nonPremiumUser } = useProfileDataContext()
  console.log(nonPremiumUser)
  const [showModal, setShowModal] = useState(showPremiumPopupContest)
  const titleText = title == "contest" ? t('premium.contest') : t('premium.tournament')

  useEffect(() => {
    EventTracking();
  }, []);
  const EventTracking = () => {
    try {
      if (title == "contest") {
        if (contest != undefined && contest != null) {
          eventTracking(events.FREE_CONTEST_NON_PREMIUM, {
            MG_GameName: contest?.gameAry?.[0]?.name,
            MG_ContestID: contest?._id,
            MG_GameID: contest?.gameAry?.[0]?._id,
          });
        }
      }
      else {
        if (tournaments != undefined && tournaments != null) {
          eventTracking(events.FREE_TOURNAMENT_NON_PREMIUM, {
            MG_GameName: tournaments?.gameAry?.[0]?.name,
            MG_GameID: tournaments?.gameAry?.[0]?._id,
            MG_TournamentID: tournaments?._id
          });
        }
      }
    } catch (e) { }
  }
  const handleClose = () => {
    setShowModal(false)
    setShowPremiumPopupContest((prev) => false);
  if(setShowJVC)
  setShowJVC(false)
  };
  const navigate = useNavigate()
      const buttonHandler = ()=>{
        setShowPremiumPopupContest(false)
        setShowModal(false)
        navigate('/home/get-premium')
      }

      
  return (
    <BaseModalSubscription showModal={showModal} setShowModal={setShowModal} text={t('premium.get_mobily_premium')} handlerClose={handleClose} buttonHandler={buttonHandler}>
      <h6 className='mt-2 d11-premium-h6'>
        {/* You have already joined {nonPremiumUser?.noOfFreeContest} free {title} today! */}
        {t('premium.you_have_already_joined_today',{n: titleText === 'tournament' ? nonPremiumUser?.noOfFreeTournament : nonPremiumUser?.noOfFreeContest, title:titleText})}  
      </h6>
      <h6 className='mt-3 d11-premium-h6'> 
        <Trans
            i18nKey="premium.subscribe_to"
            t={t}
            components={[<Link to={'/home/get-premium'} style={{ color: "var(--accent-color)"  }}> {t('premium.mobily_premium')} </Link>]}
          /></h6>
      <h6 className='d11-premium-h6'>{t('premium.premium_users_also_get_access_to_a_host_additional_benefits')}</h6>

     
    </BaseModalSubscription>
  )
}

export default ShowFreeContestModal