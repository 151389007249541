import React, { useEffect, useState } from 'react'
import LandingPage from './LandingPage'
import { url } from '../../Utilities/url'
import axios from 'axios'
import { eventTracking } from '../../firebaseAnalytics'
import { events } from '../../Utilities/appEvents'
const Policy = () => {
    useEffect(()=>{
        EventTracking(); 
      },[]);
      const EventTracking=()=>{
        eventTracking(events.PRIVACY_POLICY);
      }
    const [data, setData] = useState()
    const fetchData = async()=>{
        try{
            const res = await axios.post(url.apiUrl+'public/page/privacy-policy', {
                headers:{
                   
                   'company-code':'D11'
                }
            })
            setData(res?.data?.item?.description)
        }
        catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <LandingPage>
        <div style={{paddingTop:'7rem', paddingBottom:'1rem' , display:'flex',flexDirection:'column',  justifyContent:'center', alignItems:'center'}}>
            <h4>Privacy Policy</h4>
            <div className='col-10' dangerouslySetInnerHTML={{ __html: data }} >

            </div>
        </div>
    </LandingPage>
  )
}

export default Policy