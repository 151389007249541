import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import {
  getGamerjiPointCategoryList,
  getGamerjiPointListByCategory,
} from "../../../Utilities/controller";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const GamerjiPoints = ({
  showGamerjiPointsModal,
  setShowGamerjiPointsMOdal,
}) => {
  const [tabType, setTabtype] = useState([]);
  const {t} = useTranslation()
  const [index, setIndex] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(showGamerjiPointsModal);

  const handleClose = () => {
    setShowGamerjiPointsMOdal((prev) => false);
    setShow(false);
  };

  const getCategories = async () => {
    try {
      let data = {
        sortBy: "order",
        sort: "asc",
      };

      const res = await getGamerjiPointCategoryList(data);
      if (res?.data?.list) {
        setTabtype(res?.data?.list);
        setIndex(res?.data?.list?.[0]?._id);
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategoriesPoints = async (id) => {
    try {
      if (id) {
        let data = {
          sortBy: "order",
          sort: "asc",
          pointCategory: id,
        };

        const res = await getGamerjiPointListByCategory(data);
        if (res?.data?.list) {
          setData(res?.data?.list);
        }
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
    }
  };

  useEffect(() => {
    getCategoriesPoints(index);
  }, [index]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="title">
                <h3 class="text-center bodyBoldFont mb-5 pb-1">
                {t('right_hand_drop_down.mobily_points')}
                </h3>
              </div>
              <div className="d11-points">
                <ul className="d-flex justify-content-between">
                  {tabType.map((item, ind) => (
                    <li
                 
                      className={`d11-points-li ${
                        item?._id == index ? "active-li" : ""
                      }`}
                      style={{ width: `${100 / tabType.length}%` }}
                      key={item?._id}
                      onClick={() => setIndex((prev) => item?._id)}
                    >
                      {item?.name}
                    </li>
                  ))}
                </ul>
                {data?.map((item, ind) => (
                  <div className="d11-points-point">
                    <ul className="d-flex justify-content-between">
                      <li
                        className="d11-points-li1"
                        style={{ textAlign: "left", width: "70%" }}
                      >
                        {item?.name}
                      </li>
                      <li
                        className="d11-points-li1"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {item?.points || 0} {t('Profile.points')}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GamerjiPoints;
