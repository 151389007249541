import React, { useEffect, useReducer, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Outlet } from 'react-router'
import { func } from '../../Utilities/logFunc'
import { isUserPremium } from '../../Utilities/controller'

import { toast } from 'react-toastify'
import ProfileDataProvider from './ProfileContext'


const HomePage = () => {
const [activeNav, setActiveNav]= useState('')
// localStorage.setItem("premiumUser",true)
const [isActiveMenu, seActiveMenu] = useState(false)
const [isValid, setIsValid] = useState(false)


useEffect(()=>{
  setIsValid(true)
},[isValid])

  return (
    <ProfileDataProvider>
    <main class="dashboardMain">
      <Sidebar setActiveNav={setActiveNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu}/>

      <Navbar  activeNav={activeNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu} />
     
      {/* <Outlet /> */}
      {
        isValid &&  <Outlet />
      }
     
    </main>
    </ProfileDataProvider>
  )
}

export default HomePage