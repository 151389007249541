import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from './Utilities/routesFb';
import { func } from 'prop-types';

const Protected = ({Component}) => {
    const navigate = useNavigate()
  const [searchParams] = useSearchParams()

    useEffect(()=>{
        const isLoggedIn = localStorage.getItem("token") || searchParams.get("correlatorId")
        localStorage.setItem('corre', searchParams.get("correlatorId"))
      //  console.log(isLoggedIn)
        if(!isLoggedIn){
            navigate("/"+routes.login)
        }
    },[])
  return (
    <>
    <Component />
  
    </>
  )
}

export default Protected