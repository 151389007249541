import React, { useState } from 'react'
import LandingPage from './LandingPage'
import Slice1 from './images/slice-1.png';
import Slice2 from './images/slice-2.png';
import Slice3 from './images/slice-3.png';
// import Slice4 from './images/slice-4.png';
import AboutUs from './images/Aboutus.jpg'
import Slice5 from './images/slice-5.png';
const About = () => {
   const [active, setActive] = useState(0)
  return (
   <LandingPage  active={active}>
      
      

<div className="container">
<section className="section-explore my-4">
            <div className="row">
               <div className="explore-text-area text-center ">
                  <h1 className="heading-primary">Explore the <span className="mr-2">Future</span> of Gaming:<br/>
                     Discover
                     of Gaming: <span>Metaverse!</span>
                  </h1>
                  <p className=" mb-0">Ready to take your gaming to the next level? D11 is the ultimate destination for competitive esports
                  </p>
                  <p className=" mb-0">gaming in the Middle East. Come join us for daily tournaments, meet other gamers, and show off your
                  </p>
                  <p className=" mb-0">  skills for a chance to win prizes and get rewarded! So, what are you waiting for? Let’s get in the game
                     and level up! 
                  </p>
               </div>
            </div>
</section>
</div>
<div className='container'>

<section className="gamingSection aboutsection" >
            <div className=" m-5" >
               <div className="row g-0 d-flex align-items-center">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                     <img src={Slice1} className="img-fluid rounded-start subImage" alt="..."/>
                  </div>
                  <div className="col-md-6 col-12">
                     <div className="card-body">
                        <h2 className="sliceHeading mb-5 ">Revolutionizing the Gaming
                           Industry
                        </h2>
                        <p className="slice-secondry">With a strong vision of building communities and immersive
                           experiences for gamers, D11 Gaming is committed to making
                           the Middle East & North Africa region a gaming hub. We strive
                           to provide world-className, high-intensity games – both casual and
                           esports, to gamers around the world.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div className=" m-5 " >
               <div className="row g-0 d-flex align-items-center flip-cards">
                  <div className="col-md-6 col-12">
                     <div className="card-body">
                        <h2 className="sliceHeading mb-5 ">Harnessing the Power of Esports
                        </h2>
                        <p className="slice-secondry">At D11.gg, we understand that esports is the future of sports
                           entertainment. We provide the perfect platform for gamers to
                           reach their full potential and become successful esports
                           professionals. With us, you can enjoy fame, fortune, and
                           fandom as you take your gaming career to the next level.
                        </p>
                     </div>
                  </div>
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                     <img src={Slice5} className="img-fluid rounded-start subImage" alt="..."/>
                  </div>
               </div>
            </div>
            <div className=" m-5 " >
               <div className="row g-0 d-flex align-items-center">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                     <img src={Slice2} className="img-fluid rounded-start subImage" alt="..." />
                  </div>
                  <div className="col-md-6 col-12">
                     <div className="card-body">
                        <h2 className="sliceHeading mb-5 ">Unleashing Your Inner Gamer
                        </h2>
                        <p className="slice-secondry">We believe in the power of gaming and want to make it
                           accessible to everyone. With our unique platform, you can
                           experience all the thrills, excitement, and joy of gaming, no
                           matter your skill level. We strive to provide a safe and enjoyable
                           gaming experience that everyone can enjoy.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div className=" m-5" >
               <div className="row g-0 d-flex align-items-center flip-cards">
                  <div className="col-md-6 col-12">
                     <div className="card-body ">
                        <h2 className="sliceHeading mb-5 ">The Future of Gaming
                        </h2>
                        <p className="slice-secondry">At D11 Gaming, we are committed to revolutionizing the gaming
                           industry. We are dedicated to creating an immersive experience
                           for gamers around the world and providing them with the tools
                           to succeed. With our platform, we are paving the way for the
                           next generation of gamers.
                        </p>
                     </div>
                  </div>
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                     <img src={Slice3} className="img-fluid rounded-start subImage" alt="..." />
                  </div>
               </div>
            </div>
</section>
</div>
     
   </LandingPage>
  )
}

export default About