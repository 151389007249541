// firebaseAnalytics.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent ,setUserId} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyALVZAVFGbDzptKu3JydacXDWRu_6nvsZ4",
  authDomain: "d11-esports-gaming.firebaseapp.com",
  projectId: "d11-esports-gaming",
  storageBucket: "d11-esports-gaming.appspot.com",
  messagingSenderId: "561369877665",
  appId: "1:561369877665:web:c37624b022d003e80b34e0",
  measurementId: "G-HSEQC1K1ZQ"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//setUserId(analytics, "GU-222222");

//log event
const eventTracking=(event,parameters)=>{
  //MG_UserID, MG_TimeStamp
  var currentDate = new Date().toTimeString();
  var profileData = JSON.parse(localStorage.getItem("profile"));
  console.log(profileData);
  var customParam ={...parameters,MG_TimeStamp:currentDate };
  if(profileData!=null && profileData?.item?.user_id){
    customParam.MG_UserID = profileData?.item?.user_id;
  }else if(profileData!=null && profileData?.item?._id){
    customParam.MG_UserID = profileData?.item?._id;
  }
  console.log("Event Triggered",event,customParam)
  logEvent(analytics, event, customParam);
}

export { analytics, logEvent,eventTracking };


 


