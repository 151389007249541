import React, { useState } from "react";
import { errors } from "../../../Utilities/error";
import { Modal } from "react-bootstrap";
import Error from "../../Common/Error";
import Fea from "../../../assets/images/Refer.png";
import Copy from "../../../assets/images/copy-icon.png";
import { func } from "../../../Utilities/logFunc";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";
import { url } from "../../../Utilities/url";
import { useProfileDataContext } from "../ProfileContext";
import { Trans, useTranslation } from "react-i18next";

const ReferAFriend = ({ showReferFriend, setShowReferFriend }) => {
  const [show, setShow] = useState(showReferFriend);
  const [codeVal, setCodeVal] = useState("");
  const [error, setError] = useState("");
  const { liteProfile } = useProfileDataContext();
  const { t } = useTranslation();

  const handleClose = () => {
    setShowReferFriend(false);
    setShow(false);
  };
  const handlerSubmit = (e) => {
    e.preventDefault();
    if (!codeVal || codeVal.length <= 0) {
      setError(t('error_Please_enter_invite_code'));
    } else {
      setShowReferFriend(false);
      setShow(false);

      setError("");
    }
  };
    const translatedText = t('right_hand_drop_down.get_diamond', {
      count: liteProfile?.referAmount,
      interpolation: { escapeValue: false },
    });
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>

        <form
        // className="row justify-content-center"
        >
          <div class="row justify-content-center flex-column align-items-center">
            <h3 class="bodyBoldFont mb-5 text-center">
              {t("right_hand_drop_down.refer_a_friend")}
            </h3>
            <div
              className="col-md-6 mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className="mb-2"
                src={Fea}
                style={{ width: "12.5rem", height: "12.5rem" }}
              />
              <h2>
              <div dangerouslySetInnerHTML={{ __html: translatedText }} />
                {/* Get {" "}
              <span className='d11-refer-color'>{liteProfile?.referAmount} </span> 
                 Diamonds */}
              </h2>
              <h6 className="text-white">
                {t("right_hand_drop_down.invite_your_friend_earn_diamonds")}
              </h6>
            </div>
            <div className="col-6 mb-3 d11-profile-form ">
              <label for="inputMobileNumber" className="form-label">
                {t("right_hand_drop_down.your_referral_code")}
              </label>
              {func("refere", liteProfile?.item?.referralCode)}
              <div className="position-relative copy-form">
                <input
                  type="number"
                  className="form-control"
                  id="inputMobileNumber"
                  name="mobileNumber"
                  placeholder={liteProfile?.item?.referralCode}
                  // value={liteProfile?.item?.referralCode}
                  defaultValue={liteProfile?.item?.referralCode}
                  maxLength={14}
                  disabled
                />
                <span
                  className="copy-icon"
                  //  style={{position:'absolute',top:'50%', right:'10%',cursor:'pointer'}}
                >
                  <img
                    src={Copy}
                    className="icon-24"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        liteProfile?.item?.referralCode
                      );
                      toast.info(t('info_Copied'), {
                        // position: "top-left",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  />
                </span>
              </div>
              {error && <Error error={error} />}
            </div>

            <div className="col-md-6">
              <RWebShare
                data={{
                  text:
                    t("right_hand_drop_down.join_text", {
                      name: liteProfile?.item?.referralAmount,
                    }) +
                    t("right_hand_drop_down.referral_code", {
                      name: liteProfile?.item?.referralCode,
                    }) +
                    t("right_hand_drop_down.register_now", {
                      name: url.client_base_url,
                    }),
                  url: `${url.client_base_url}`,
                  title: t("Profile.join_me", { name: "Mobily" }),
                }}
                onClick={() => console.info("share successful!")}
              >
                <span
                  className="btn btn-primary btn-share "
                  style={{ width: "100%" }}
                  // onClick={(e)=>handlerSubmit(e)}
                >
                  {t("right_hand_drop_down.refer_mobily")}
                </span>
              </RWebShare>
              {/* {
                                error && <p className='error' style={{ textAlign: 'center' }}>{error}</p>
                            } */}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ReferAFriend;

{
  /* export default ReferAFriend; */
}
