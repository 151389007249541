import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { json, useNavigate } from "react-router";
import moment from "moment";
import { url } from "../../../Utilities/url";
import { gamerjiTournamentJoin, getHeaderApi } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PayIcon from "../../../assets/images/pay-icon-svg.svg";
import TeamRegisterModal from "./TeamRegisterModal";
import Loader from "../../Common/Loader";
import D11Gold from '../../../assets/images/D11_Grandmaster_Icon_Gold.png'
import Champion from '../../../assets/images/championIcon.png'
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const TournamentConfirmationModal = ({
  showTournamentConfirmationModal,
  setShowTournamentConfirmationModal,
  tournaments,
  match,
  walletData,
  userInGameName,
  setShowPremiumPopupTournament
}) => {
  let matchData = match;
  let premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
  const [show, setShow] = useState(showTournamentConfirmationModal);
  const navigate = useNavigate();
  const handleClose = () => {
    setShowTournamentConfirmationModal((prev) => false);
    setShow(false);
  };
  const [showTeamRegisterModal, setShowTeamRegisterModal] = useState(false);
  const [postData, setPostData] = useState(null);
  const [noOfPlayer, setNoOfPlayer] = useState(0);
  const [game, setGame] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { profileData, updateProfileData } = useProfileDataContext()
  const { t } = useTranslation();

  const handlerJoinTournament = () => {
    eventTracking(events.JOIN_TOURNAMENT,{
      MG_GameName:tournaments?.gameAry?.[0]?.name,
      MG_GameID:tournaments?.gameAry?.[0]?._id,
      MG_TournamentID:tournaments?._id
    });
    // setShowTournamentConfirmationModal(false)
    if(!premiumUser && walletData?.walletBalance < walletData?.toPay){
      toast.error("Insufficient Balance")
      navigate('/home/get-premium')
    }
    else{

    
    setLoading(true);
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    let data = {
      contest: matchData?._id,
      currencyCode: tournaments?.currency?.[0]?.inCurrency?._id,
      event: tournaments?._id,
      inGameUserName: userInGameName,
      isActive: true,
      type: "event",
      uniqueIGN: userInGameName,
      user: profileData?.item?.user_id,
    };
    // console.log(data)
    if (tournaments?.gameTypeAry?.players > 1) {
      setNoOfPlayer(tournaments?.gameTypeAry?.players || 0);
      setPostData(data);
      setGame(tournaments?.gameAry?.[0]?._id);
      setShowTeamRegisterModal(true);
      
      setLoading(false);
    } else {
      joinTournament(data);
    }
  }
  };

  const joinTournament = async (data) => {
    // console.log("clikced")
    try {
      const res = await gamerjiTournamentJoin(data);
      if (res?.data?.success) {
        const resProfile = await getHeaderApi();
        updateProfileData(resProfile?.data)
        setLoading(false);

        setShowTournamentConfirmationModal((prev) => false);
        navigate("/home/tournament-details/" + data?.event);
      } else {
        setLoading(false);

        toast.error(res?.data?.errors?.[0]?.msg);
        setShowTournamentConfirmationModal((prev) => false);
      }
      func(res);
    } catch (error) {
      setLoading(false);
      if(error?.response?.data?.errors?.[0]?.data == "events.validation.MAX_FREE_TRANSACTION"){
        setShowPremiumPopupTournament(true)
      }
      else 
      toast.error(error?.response?.data?.errors?.[0]?.msg);
      setShowTournamentConfirmationModal((prev) => false);
    }
  };


  // const handlerJoinTournament = () => {
  //   navigate('/home/tournament-details/' + '1328478')
  // }

 
  if (isLoading) return <Loader />;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tournamentModal modal fade"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center">
            <div class="col-lg-11 col-12 px-lg-4">
             {/* {console.log(localStorage.getItem("planname"))} */}
              {
                premiumUser == true ? 
<div className="text-center">
            <img  style={{ width: "7.5rem", height: "7.5rem" }} src={localStorage.getItem("planName") == "D11_CHAMPION"?  Champion : D11Gold } /> 
            <h2 className='d11-premium mt-2  mb-4'>{t("tournaments.premium")}</h2>
            </div>
            : ""

              }
              
                  
              <div class="title ">
                <h3 class="text-center bodyBoldFont mb-4 pb-3">
                  {tournaments?.title}
                </h3>
              </div>
              <div class=" text-center">
                <div class="d-flex justify-content-between">
                  <h4 class="mb-3">
                    {tournaments?.gameAry?.[0].name} -{" "}
                    {tournaments?.gameTypeAry?.name}
                  </h4>
                  <h4 class="mb-3">
                    <span>{moment(matchData?.date).format("Do MMM YYYY")}</span>
                    <span> {moment(matchData?.time).format("hh:mm A")}</span>
                  </h4>
                </div>
                <div class="d11-round-text p-4 text-start">
                  <h3 class="mb-2 bodyBoldFont">{t("contest.confirmation")}</h3>
                  <h4 class="mb-0 d-flex align-items-center">
                  {tournaments?.currency?.[0]?.inCurrency?.code === "D11"
                      ? t("contest.diamond_balance")
                      : t("contest.coin_balance")} =
                    <span class="icon px-2">
                      <img
                        class="icon-20 d11-tournament-coin-img"
                        src={
                          url?.imageUrl +
                          tournaments?.currency?.[0]?.inCurrency?.img?.default
                        }
                        alt=""
                      />
                    </span>
                    {walletData?.walletBalance}
                  </h4>
                </div>
                <div class="d-flex flex-column text-start mt-4 pt-2">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">{t("contest.entry_fee")}</h4>
                      <h4 class="mb-0">
                        <span class="icon me-2 d11-tournament-coin-img">
                          {tournaments?.entryFee > 0  ? (
                            <img
                              className="icon-20 d11-tournament-coin-img"
                              src={
                                url.imageUrl +
                                tournaments?.currency?.[0]?.inCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </span>
                        {tournaments?.entryFee > 0
                          ? tournaments?.entryFee
                          : t("tournaments.free")}
                      </h4>
                    </li>
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">
                        <span class="icon me-2">
                          <img class="icon-24" src={PayIcon} alt="" />
                        </span>
                        {t("contest.to_pay")}
                      </h4>
                      <h4 class="mb-0">
                        <span class="icon me-2 d11-tournament-coin-img">
                          {tournaments?.entryFee > 0   && premiumUser === false ? (
                            <img
                              className="icon-20 d11-tournament-coin-img"
                              src={
                                url.imageUrl +
                                tournaments?.currency?.[0]?.inCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </span>
                        {/* {console.log(premiumUser,  typeof premiumUser, tournaments?.entryFee > 0  && premiumUser === false ? walletData?.toPay : t("tournaments.free"))} */}
                        {tournaments?.entryFee > 0  && premiumUser === false ? walletData?.toPay : t("tournaments.free")}
                      </h4>
                    </li>
                    {func("toucode 207", tournaments?.currency?.[0]?.inCurrency?.code)}
                    {
                      premiumUser == true && (
                        <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">
                        
                        {/* {tournaments?.currency?.[0]?.inCurrency?.code === "D11" ? "Coins" : tournaments?.currency?.[0]?.inCurrency?.code} Saved */}
                        {t('tournaments.saved', {coin:`${tournaments?.currency?.[0]?.inCurrency?.code=== "D11" ? "Coins" : tournaments?.currency?.[0]?.inCurrency?.code}`})}
                      </h4>
                      <h4 class="mb-0">
                        <span class="icon me-2 d11-tournament-coin-img">
                          {tournaments?.entryFee > 0   ? (
                            <img
                              className="icon-20 d11-tournament-coin-img"
                              src={
                                url.imageUrl +
                                tournaments?.currency?.[0]?.inCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </span>
                        {func("wallet data", walletData)}
                        {tournaments?.entryFee > 0 ? walletData?.toPay : "Free"}
                      </h4>
                    </li>
                      )
                    }
                  </ul>
                </div>
                <div class="row justify-content-center mt-4 pt-2">
                  <div class="col-lg-7">
                    <div class="d-grid">
                      <button
                        class="btn btn-primary btn-arrow"
                        onClick={handlerJoinTournament}
                      >
                        {tournaments?.isJoined
                          ? t("tournaments.joined")
                          : t("tournaments.join_tournament")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showTeamRegisterModal && (
        <TeamRegisterModal
          showTeamRegisterModal={showTeamRegisterModal}
          setShowTeamRegisterModal={setShowTeamRegisterModal}
          postData={postData}
          type={"event"}
          noOfPlayer={noOfPlayer}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          game={game}
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
          userInGameName={userInGameName}
        />
      )}
    </>
  );
};

export default TournamentConfirmationModal;
