import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BaseAuth from "../../Auth/BaseAuth";
import { func, func1 } from "../../../Utilities/logFunc";
import { errors } from "../../../Utilities/error";
import Error from "../../Common/Error";
import {
  getContestViaCode,
  getTournamentViaCode,
  walletUsageLimit,
  requestWalletUsageLimit,
  getUserInGameName,
  getAccountProfile,
} from "../../../Utilities/controller";
import { useNavigate } from "react-router";

import RulesModal from "../Tournament/RulesModal";
import JoinModal from "../Tournament/JoinModal";
import WinningBreakUp from "../Tournament/WinningBreakUp";
import TournamentModal from "../Tournament/TournamentModal";
import TournamentConfirmationModal from "../Tournament/TournamentConfirmationModal";
import ContestConfirmationModel from "../Contest/ContestConfirmationModel";
import ContestJoinModal from "../Contest/JoinModal";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoinScrim from "./JoinScrim";
import BasicProfile from "../Tournament/BasicProfile";
import BasicProfileContest from "../Tournament/BasicProfileContest";
import BasicProfilePrivateContest from "../Tournament/BasicProfilePrivateContest";
import TeamRegisterScrim from "../PrivateContest/TeamRegisterScrim";
import Loader from "../../Common/Loader";
import ShowFreeContestModal from "../../Subscription/ShowFreeContestModal";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const JoinViaInviteCodeModal = ({ showJVC, setShowJVC, code }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userDataInfo, setUserData] = useState("");
  const [show, setShow] = useState(showJVC);
  const [codeVal, setCodeVal] = useState("");
  const [error, setError] = useState("");
  const [contestType, setContestType] = useState("contest");
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showContestJoinModal, setShowContestJoinModal] = useState(false);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showTournamentConfirmationModal, setShowTournamentConfirmationModal] =
    useState(false);
  const [isLoading, setLoading] = useState(false);

  const [showBasicProfileModal, setShowBasicProfileModal] = useState(false);
  const [showPremiumPopupContest, setShowPremiumPopupContest] = useState(false);
  const [showPremiumPopupTournament, setShowPremiumPopupTournament] = useState(false);


  const [showBasicProfileContestModal, setShowBasicProfileContestModal] =
    useState(false);
  const [
    showBasicProfilePrivateContestModal,
    setShowBasicProfilePrivateContestModal,
  ] = useState(false);

  const [selectedMatch, setMatch] = useState(null);
  const [selected, setselected] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [activeTournament, setActiveTournament] = useState(null);
  const [activeCurrencyWallet, setActiveCurrencyWallet] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [showContestConfirmationModal, setShowContestConfirmationModal] =
    useState(false);
  const [showJoinScrimModal, setShowJoinScrimModal] = useState(false);
  const [scrimData, setScrimData] = useState({});
  const [showTeamRegisterScrimModal, setShowTeamRegisterScrimModal] =
    useState(false);
  const [activeInGameName, setActiveInGameName] = useState(null);
  const [inGameNames, setInGameNames] = useState([]);
  const [userInGameName, setUserInGameName] = useState('')
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  const [searchParams] = useSearchParams()

  const { profileData, updateProfileData } = useProfileDataContext()


  const handlerJoin = async (data) => {
    getWallet(data);
    setActiveTournament(data);
    // setShowTournamentModal(true);
    setSelectedTournament(data);
    // let checkInNameGame = await getUserInGameNameData();
    if (activeInGameName) {
      setShowTournamentModal(true);
    } else {
      setShowBasicProfileModal(true);
    }
  };

  useEffect(() => {
    // var user_data = JSON.parse(localStorage.getItem("profile"));
    // setUserData(user_data);
    if(code)
    joinViaCode(code)
  }, []);


  // const getUserInGameNameData = async () => {
  //   try {
  //     let data = {
  //       search: {
  //         // phone: userDataInfo?.item?.phone,
  //         // phoneCode: userDataInfo?.item?.phoneCode,
  //         // game: localStorage.getItem("activeGameId"),
  //         "user": userData?.user_id,

  //       },
  //     };
  //     func("localStorage.getItem()", localStorage.getItem("activeGameId"))
  //     const res = await getUserInGameName(data);
  //     if (res?.data?.data && res?.data?.data?.length > 0) {

  //       setInGameNames(res?.data?.data)

  //       // let index = res?.data?.data.findIndex(obj => obj.game == gameType?.game?._id)
  //       // console.log("index", index);

  //       // if (index > -1) {
  //       // setUserInGameName(res?.data?.data[index].userINGameName || "")
  //       // }
  //       //setLoader(false);
  //       return true;
  //     } else {
  //       //  setLoader(false);
  //       return false;
  //     }
  //   } catch (error) {
  //     // setLoader(false);
  //     return false;
  //   }
  // };

  const getWallet = async (dataItem) => {
    // console.log(dataItem)
    try {
      let data = {
        event: dataItem?._id,
        type: "event",
        currencyCode: dataItem?.currency?.[0]?.inCurrency?._id,
        entryFee: dataItem?.entryFee,
      };

      const res = await walletUsageLimit(data);
      if (res?.data?.item) {
        setActiveCurrencyWallet(res?.data?.item);
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      setLoading(false);
    }
  };

  const handlerJoinPopup = (data) => {
    func("clikced");
    setActiveTournament(data);
    setShowJoinModal(true);
  };

  const handlerJoinNow = async (tournaments) => {
    // console.log("handller join now",tournaments?.gameAry?.[0]?._id)

    try {
      getWallet(tournaments);
      setSelectedTournament(tournaments);
      let payload = {
        search: {
          user: profileData?.item?.user_id,
          game:tournaments?.gameAry?.[0]?._id
        },
      };
      setLoading(true)
      const res = await getUserInGameName(payload)
      // const game = localStorage.getItem()
      func("response", res)
      if (res?.data?.success) {
        // func(JSON.parse(localStorage.getItem("userInGameNames"))?.data, tournaments?.gameAry?.id)
        let gameInName = res?.data?.data.filter((ele) => ele?.game === tournaments?.gameAry[0]?._id)
        // console.log("ingamename", gameInName)

        if (gameInName.length == 0) {
          setLoading(false)

          setShowBasicProfileModal(true);
        }
        else {
          // console.log(">>>>>>>>>>>>>>")
          var today = new Date();
          let roundaDateTime = tournaments?.rounds[0]?.matches
          var tempRound = roundaDateTime?.[roundaDateTime.length - 1];
          // console.log("<<<<<<<<<<<<",roundaDateTime)
          var cdate = moment(tempRound.date).format("y-MM-DD");
          var ctime = moment(tempRound.time).format("HH:mm:ss");
          var contestDateTime = moment(cdate + " " + ctime);
          // console.log( today , new Date(contestDateTime))
          if (today > new Date(contestDateTime)) {
            toast.error(t('error_Oops_This_tournament_has_already_started'))
          }
          else{
          setUserInGameName(gameInName[0]?.userINGameName)
          setShowJoinModal(false);
          // setShowTournamentModal(true);
          setShowTournamentModal(true);
          setActiveTournament(tournaments);
        
          setLoading(false)
        }
      }

      }

      // let checkInNameGame = await getUserInGameNameData();

      // if (activeInGameName) {
      //   // setShowJVC(false)  
      //   setShowTournamentModal(true);
      // }
      else {
        // setShowJVC(false)
        setLoading(false)
        setShowBasicProfileModal(true);

      }
    }
    catch (error) {
      setLoading(false)
      setShowBasicProfileModal(true);
      
    }
  };

  const handlerWinning = () => {
    func("clicked");
    setShowWinningModal((prev) => true);
  };

  const handleClose = () => {
    setShowJVC(false);
    setShow(false);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    func("JVC", codeVal);

    if (!codeVal || codeVal.length <= 0) {
      setError(t('error_Please_enter_invite_code'));
    } else {
      joinViaCode(codeVal);
      //   setShowJVC(false);
      //   setShow(false);
      //   setShowJoinModal(true);
      //   setError("");
    }
  };

  const joinViaCode = async (code) => {
    func("code called", )
    setLoading(true)
    let gameName = ""
    try {
      let data = {
        search: {
          user: profileData?.item?.user_id,
        },
      };

      const res = await getContestViaCode(code, data);

      if (res?.data?.success == true) {
        if (res?.data?.type && res?.data?.type == "scrim") {
          setContestType("privateContest");
          setScrimData(res?.data?.data);
          localStorage.setItem(
            "activeGameId",
            res?.data?.data?.gameAry?.[0]?.id
          );
          localStorage.setItem(
            "activeGame",
            res?.data?.data?.gameAry?.[0]?.name
          );
          if(res?.data?.data?.userInGameName)
           gameName = res?.data?.data?.userInGameName
          let index = inGameNames?.findIndex(
            (obj) => obj.game == res?.data?.data?.gameAry?.id
          );

          if (index > -1) {
            setActiveInGameName(res?.data?.data[index].userINGameName || "");
          }
          setLoading(false)
          setShow(false);
          setShowJoinScrimModal(true);
        } 
        else if (res?.data) {
          setContestType("contest");
          setShow(false);
          setShowContestJoinModal(true);
          setActiveTournament(res?.data?.data);
          console.log(res?.data?.data?.gameAry)

          localStorage.setItem(
            "activeGameId",
            res?.data?.data?.gameAry?.[0]?._id
          );
          localStorage.setItem(
            "activeGame",
            res?.data?.data?.gameAry?.[0]?.name
          );
          if(res?.data?.data?.userInGameName)
          setActiveInGameName(res?.data?.data?.userINGameName || "");
          // let index = inGameNames?.findIndex(
          //   (obj) => obj.game == res?.data?.data?.gameAry?.[0]?.id
          // );

          // if (index > -1) {
          //   setActiveInGameName(res?.data?.data[index].userINGameName || "");
          // }

          func("contest", res?.data?.data);
          setLoading(false)
        } else {
          func("146 no.");
          joinViaCodeTournament(code);
        }
      } else {
        func("150 no");
        joinViaCodeTournament(code);
      }
      func(res);
      setLoading(false)
    } catch (error) {
      joinViaCodeTournament(code);
      // toast.error(error?.response?.data?.errors[0]?.msg)
      setLoading(false)
      func("error", error);
    }
  };

  const joinViaCodeTournament = async (code) => {
    try {
      let userData = localStorage.getItem("userData");
      userData = JSON.parse(userData);

      let data = {
        code: code,
        user: profileData?.item?.user_id,
      };

      const res = await getTournamentViaCode(data);

      if (res?.data?.success == true) {
        setContestType("event");
       
        setActiveTournament(res?.data?.item);
        // console.log(res?.data?.item?.gameAry?.[0]?._id)
        localStorage.setItem(
          "activeGameId",
          res?.data?.item?.gameAry?.[0]?._id
        );
        localStorage.setItem("activeGame", res?.data?.item?.gameAry?.[0]?.name);

        setShow(false);
        // handleClose();
        func("309","")
        setShowJoinModal(true);
      } else {
        toast.error(res?.data?.errors?.[0]?.msg);
        if (searchParams.get('code') !== '') {
          setShowJVC(false);
          setShow(false);
        }
      }
    } catch (error) {
      // func("avatar 316", error);
      if (
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ===
          "middlewares.token_parser.validation.token_expired"
      ){
        logout();
        return;
      }
      toast.error(error?.response?.data?.errors?.[0]?.msg);
      if (searchParams.get('code') !== '') {
        setShowJVC(false);
        setShow(false);
      }
    }
  };

  useEffect(() => { }, [showTournamentConfirmationModal]);

  const handleJoinContest = async (item) => {
    let gamename = ""
    setselected(item);
    // console.log(item?.gameAry)
    localStorage.setItem(
      "activeGameId",
     item?.gameAry?.[0]?._id
    );
    localStorage.setItem("activeGame", item?.gameAry?.[0]?.name);
    try {

      if(item?.userInGameName)
      
      gamename = item?.userInGameName
      else{
        let payload = {
          search: {
            user: profileData?.item?.user_id,
            game:item?.gameAry?.[0]?._id
          },
        };
        setLoading(true)
        const res = await getUserInGameName(payload)
        if (res?.data?.success)
        gamename = res?.data?.data.filter((ele) => ele?.game === item?.gameAry?.[0]?._id)
      }
     
      // const game = localStorage.getItem()
      
      if (!gamename) {
        // func(JSON.parse(localStorage.getItem("userInGameNames"))?.data, item?.gameAry?.id)

          setShowBasicProfileContestModal(true);
        }
        else 
        {
          // console.log("gamenme>>>>>>>>>>>>>>",gamename)
          setUserInGameName(gamename[0]?.userINGameName)
          setShowBasicProfileContestModal(false)
        let payload = {
          contest: item?._id,
          currencyCode: item?.currency?.[0]?.inCurrency?._id,
          entryFee: item?.entryFee,
          type: "contest",
        };
        setLoading(true);
        const res = await requestWalletUsageLimit(payload);
        if (res?.success && res?.item) {
          func("wallet", res.item);
          setWalletData(res?.item);
          setTimeout(async function () {
            if (item?.isJoined) {
              setShowContestJoinModal(false);
              setShowContestConfirmationModal(false);
              navigate("/home/contest-details/" + item._id);
            } else {
              setShowContestJoinModal(false);
              // setShowContestConfirmationModal(true);
              setShowContestConfirmationModal(true);
              // let checkInNameGame = await getUserInGameNameData();
              // if (activeInGameName) {
              //   setShowContestConfirmationModal(true);
              // } else {
              //   setShowBasicProfileContestModal(true);
              // }
            }
          }, 500);
          setLoading(false);
        } else {
          func("getWalletUsageLimit err");
        }
      }
      
     } catch (error) {
      setShowBasicProfileContestModal(true);
        func("getWalletUsageLimit error", error?.response?.data);
        setLoading(false);
      }
    };
if(isLoading) return <Loader />
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="auth-modal modal fade "
          centered
        >
          <Modal.Body>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
              aria-label="Close"
            ></button>

            <form className="row justify-content-center">
              <div
                class="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 class="bodyBoldFont mb-5 text-center">
                {t("right_hand_drop_down.join_via_invite_code")}
                </h3>
                <div className="col-sm-6 col-10 mb-3">
                  <label for="inputMobileNumber" className="form-label">
                  {t("right_hand_drop_down.invite_code")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputMobileNumber"
                    placeholder={t("right_hand_drop_down.enter_invite_code")}
                    name="mobileNumber"
                    onChange={(e) => setCodeVal(e.target.value.toUpperCase())}
                    value={codeVal}
                  />
                  {error && <Error error={error} />}
                </div>

                <div className="col-sm-6 col-10">
                  <button
                    className="btn btn-primary btn-arrow"
                    style={{ width: "100%" }}
                    onClick={(e) => handlerSubmit(e)}
                  >
                   {t("contest.submit")}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {showJoinModal && (
          <JoinModal
            showJoinModal={showJoinModal}
            setShowJoinModal={setShowJoinModal}
            handlerWinning={handlerWinning}
            handlerJoinNow={handlerJoinNow}
            tournaments={activeTournament}
            setShowRulesModal={setShowRulesModal}
            setSelectedTournament={setSelectedTournament}
            setShowJVC={setShowJVC}
            setShowBasicProfileModal={setShowBasicProfileModal}
          />
        )}
        {
          func("JVC Winning", selectedTournament)
        }
        {showWinningModal && (
          <WinningBreakUp
            showWinningModal={showWinningModal}
            setShowWinningModal={setShowWinningModal}
            selectedTournament={activeTournament}
          />
        )}
        {showTournamentModal && (
          <TournamentModal
            showTournamentModal={showTournamentModal}
            setShowTournamentModal={setShowTournamentModal}
            setShowTournamentConfirmationModal={
              setShowTournamentConfirmationModal
            }
            setMatch={setMatch}
            tournaments={activeTournament}
            setShowJVC={setShowJVC}
          />
        )}

        {showTournamentConfirmationModal && (
          <TournamentConfirmationModal
            showTournamentConfirmationModal={showTournamentConfirmationModal}
            setShowTournamentConfirmationModal={
              setShowTournamentConfirmationModal
            }
            tournaments={selectedTournament}
            walletData={activeCurrencyWallet}
            match={selectedMatch}
            userInGameName={userInGameName}
            setShowPremiumPopupTournament = {setShowPremiumPopupTournament}
          />
        )}

        {showRulesModal && (
          <RulesModal
            showRulesModal={showRulesModal}
            setShowRulesModal={setShowRulesModal}
            selectedTournament={selectedTournament}
          />
        )}

        {showContestJoinModal && (
          <ContestJoinModal
            showJoinModal={showContestJoinModal}
            setShowJoinModal={setShowContestJoinModal}
            handleJoinContest={() => handleJoinContest(activeTournament)}
            handlerWinning={handlerWinning}
            tournaments={activeTournament}
            setShowRulesModal={setShowRulesModal}
            setselected={setselected}
            userINGameName={userInGameName}
            setShowJVC  = {setShowJVC }
          />
        )}
      {/* {console.log("userInGamename Contest", userInGameName)} */}
        {showContestConfirmationModal && (
          <ContestConfirmationModel
            showContestConfirmationModal={showContestConfirmationModal}
            setShowContestConfirmationModal={setShowContestConfirmationModal}
            tournaments={selected}
            walletData={walletData}
            setShowJVC={setShowJVC}
            userInGameName={userInGameName}
            setShowPremiumPopupContest = {setShowPremiumPopupContest}
            
          />
        )}
        {/* {showJoinScrimModal && (
          <JoinScrim
            showJoinScrimModal={showJoinScrimModal}
            setShowJoinScrimModal={setShowJoinScrimModal}
            item={scrimData}
            setShowJVC={setShowJVC}
          />
        )} */}

        {showBasicProfileModal && (
          <BasicProfile
            showBasicProfileModal={showBasicProfileModal}
            setShowBasicProfileModal={setShowBasicProfileModal}
            game={localStorage.getItem("activeGameId")}
            setShowTournamentModal={setShowJoinModal}
          />
        )}

        {showBasicProfileContestModal && (
          <BasicProfileContest
            showBasicProfileModal={showBasicProfileContestModal}
            setShowBasicProfileModal={setShowBasicProfileContestModal}
            game={localStorage.getItem("activeGameId")}
            setShowContestConfirmationModal={setShowContestJoinModal}
          />
        )}
        {showJoinScrimModal && (
          <JoinScrim
            showJoinScrimModal={showJoinScrimModal}
            setShowJoinScrimModal={setShowJoinScrimModal}
            item={scrimData}
            setShowTeamRegisterScrimModal={setShowTeamRegisterScrimModal}
            setShowJVC={setShowJVC}
            setUserInGameName={setUserInGameName}
          />
        )}
        {showTeamRegisterScrimModal && (
          <TeamRegisterScrim
            showTeamRegisterScrimModal={showTeamRegisterScrimModal}
            setShowTeamRegisterScrimModal={setShowTeamRegisterScrimModal}
            noOfPlayer={scrimData?.gameType?.players}
            game={scrimData?.game?._id}
            scrimId={scrimData?._id}
            userInGameName={userInGameName}
            setShowJVC={setShowJVC}
          />
        )}
        {
          showPremiumPopupContest && (
            <ShowFreeContestModal showPremiumPopupContest ={showPremiumPopupContest} setShowPremiumPopupContest={setShowPremiumPopupContest}  setShowJVC={setShowJVC} title={"contest"} />
          )
        }
        {
          showPremiumPopupTournament && (
            <ShowFreeContestModal showPremiumPopupContest ={showPremiumPopupTournament} setShowPremiumPopupContest={setShowPremiumPopupTournament} title={"tournament"}   setShowJVC={setShowJVC} />
          )
        }
      </>
    );
  };

  export default JoinViaInviteCodeModal;
