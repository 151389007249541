import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import moment from "moment";
import { url } from "../../../Utilities/url";
import { func } from "../../../Utilities/logFunc";
import { getHeaderApi, requestContestJoin, getHtml5GameUpdate } from "../../../Utilities/controller";
import PayIcon from '../../../assets/images/pay-icon-svg.svg'
import TeamRegisterModal from "../Tournament/TeamRegisterModal";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import D11Gold from '../../../assets/images/D11_Grandmaster_Icon_Gold.png'
import Champion from '../../../assets/images/championIcon.png'
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 



const GameConfirmationModel = ({
  showGameConfirmationModel,
  setShowGameConfirmationModel,
  game,
  subGame,
  joinGame,
  setAllowToViewGame,
  setJoinId
}) => {

  // var user_data = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [show, setShow] = useState(showGameConfirmationModel);

  const handleClose = () => {
    setShowGameConfirmationModel((prev) => false);
    setShow(false);
    joinGame(false);
  };

  const handlerJoinContest = async () => {
    const payload = {
      "html5Category": game?._id,
      "htmlGame": subGame?._id,
      "status": "start"
    };

    const res = await getHtml5GameUpdate(payload);
    if (res?.data?.success) {
      setJoinId(res?.data?.item?._id)
      setAllowToViewGame(true);
      setShow(false);
      setShowGameConfirmationModel((prev) => false);
      joinGame(true);
    }
    else {
      setAllowToViewGame(false);
      setShow(false);
      setShowGameConfirmationModel((prev) => false);
      joinGame(false);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="tournamentModal modal fade"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center">
            <div class="col-11 px-lg-4">
              <div class="title">
                <h3 class="text-center bodyBoldFont mb-4 pb-3">
                  {game?.name + " - " + subGame?.name}  
                </h3>
              </div>
              <div class=" text-center">
                <div class="bg-warning p-4 text-start">
                  <h3 class="mb-2 bodyBoldFont">{t("contest.confirmation")}</h3>
                </div>
                <div class="d-flex flex-column text-start mt-4 pt-2">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between px-0 py-3">
                      <h4 class="bodyBoldFont mb-0">{t("contest.entry_fee")}</h4>
                      <h4 class="mb-0">
                        {t("tournaments.free")}
                      </h4>
                    </li>
                  </ul>
                </div>
                <div class="row justify-content-center mt-2 pt-2">
                  <p>{t("free_game.enjoy_free_games_on_d11")}</p>
                </div>
                <div class="row justify-content-center mt-4 pt-2">
                  <div class="col-lg-7">
                    <div class="d-grid">
                      <button
                        class="btn btn-primary btn-arrow"
                        onClick={handlerJoinContest}
                      >
                        {t("free_game.join_game")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GameConfirmationModel;
