import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { func } from "../../../Utilities/logFunc";
import { getLeaderboardMonthlyList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import Search from '../../../assets/images/search-icon-svg.svg'
import Champion from '../../../assets/images/championIcon.png';
import Master from '../../../assets/images/grandMasterIcon.png';
import { useTranslation } from "react-i18next";
const ByMonth = ({ leaderboardType }) => {
  let [leaderBoardData, setLeaderBoardData] = useState([]);
  let [limit, setLimit] = useState(15);
  let [count, setCount] = useState(1);
  let [search, setSearch] = useState("");
  let [totalRecord, setTotalRecord] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const {t} = useTranslation()

  const fetchLeaderBoardData = async (totalCount) => {
    try {
      if (leaderboardType === "byMonthly") {
        setLoading(true);
        let data = {
          skip: 0,
          limit: parseInt(limit) * parseInt(totalCount),
          type: "monthly",
          q: search.trim(),
        };

        const res = await getLeaderboardMonthlyList(data);
        if (res?.data?.list) {
          setLeaderBoardData(res?.data?.list);
          setTotalRecord(res?.data?.count);
          setLoading(false);
        }
        func(res);
      }
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderBoardData(count);
  }, [leaderboardType]);

  const checkPhoneNumber = (num) => {
    var trailingCharsIntactCount = 5;
    if (num?.length) {
      let str =
        new Array(num.length - trailingCharsIntactCount + 1).join("x") +
        num.slice(-trailingCharsIntactCount);
      return str;
    } else {
      let str = "xxxxx";
      return str;
    }
  };

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(count) + 1;
    setCount(totalCount);
    fetchLeaderBoardData(totalCount);
  };

  // useEffect(() => {
  //   fetchLeaderBoardData(count);
  // }, [search]);

  const leaderBoardSearch = () => {
    // if (search.length > 0)
      fetchLeaderBoardData(count);
  }
  return (
    <>
      <div
        class="tab-pane fade"
        id="monthly-tab-pane"
        role="tabpanel"
        aria-labelledby="monthly-tab"
        tabindex="0"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div class="d-flex">
              <form class="d-flex mb-4 pb-1 ms-auto searchForm" role="search">
                <input
                  class="form-control me-2"
                  type="search"
                  placeholder={t('leaderboard.search')}
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button className="cursor"  onClick={() => leaderBoardSearch()} 
                // style={{ marginTop: 8, right: 0, marginRight: 15, position: "absolute" }}
                >
                  <img src={Search} alt="" />
                </button>
              </form>
            </div>
            <div class="card tournamentCard">
              <div class="card-header  p-3">
                <div class="row justify-content-between align-items-center">
                  <div class="col-sm-3 col-2 text-center">
                    <h3 class="bodyBoldFont mb-0 mt-2 text-center h4">{t('leaderboard.rank')}</h3>
                  </div>
                  <div class="col-sm-3 col-4">
                    <h3 class="bodyBoldFont mb-0 mt-2 h4">{t('leaderboard.team_name')}</h3>
                  </div>
                  <div class="col-3 text-center">
                    <h3 class="bodyBoldFont mb-0 mt-2 text-center h4">
                    {t('leaderboard.points')}
                    </h3>
                  </div>
                  <div class="col-3 text-center">
                    <h3 class="bodyBoldFont mb-0 mt-2 text-center h4">{t('leaderboard.level')}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="card playersDetailCard mt-1 mb-4">
              <div class="card-body">
                <ul class="list-unstyled mb-0">
                  {leaderBoardData.length > 0 ?
                    leaderBoardData.map((data, indp) => (
                      <li class="row justify-content-between align-items-center" key={indp}>
                        <div class="col-sm-3 col-2 text-center">
                          <h5 class="bodyNormalFont mb-0">{data?.rank || 0}</h5>
                        </div>
                        <div
                          class={`col-sm-3 col-4 d-flex`}
                        >
                           <div class="d-flex w-100">
                           <div class="icon me-2 d-block">
                              {data?.level?.featuredImage?.default && (
                                <div class="hexagon" id="hexagon">
                                <div className="polygon-image">
                                <img
                                  class="icon-40"
                                  src={
                                    url.imageUrl +
                                    data?.level?.featuredImage?.default
                                  }
                                  alt={data?.user?.gamerjiName}
                                />
                                </div>
                                </div> 
                              )}
                            </div>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                              {/* <span> */}
                                <h6 class="text-golden text-ellipsis_leaderboard mt-2 mb-0">
                                  {
                                  // data?.user?.gamerjiName?.length > 33
                                  //   ? data?.user?.gamerjiName.substring(
                                  //       0,
                                  //       33 - 3
                                  //     ) + "..."
                                  //   : 
                                    data?.user?.gamerjiName}
                                </h6>
                                {/* <h6 class="mb-0">
                                  {checkPhoneNumber(data?.user?.phone)}
                                </h6> */}
                              {/* </span> */}
                              {data?.isPremium ? (
                                <img
                                  className="icon-premium ms-2"
                                  src={data?.planName == "D11_CHAMPION" ? Champion : Master}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-3 text-center">
                          <h5 class="bodyNormalFont mb-0">
                            {data?.points || 0}
                          </h5>
                        </div>
                        <div class="col-3 text-center">
                          <h5 class="bodyNormalFont mb-0">
                            {data?.level?.num || 0}
                          </h5>
                        </div>
                      </li>
                    ))
                  : 
                  <h3 className="mt-2 text-center">{t('notFound.no_records_found')}</h3>
                  }
                </ul>
              </div>
              
            </div>
            {leaderBoardData.length < totalRecord && (
                <div class="card-footer d-flex justify-content-center">
                  <button
                    class="btn btn-primary col-md-2 text-center"
                    onClick={() => loadMoreBtnClick()}
                  >
                   {t('tournaments.load_more')}
                  </button>
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default ByMonth;
