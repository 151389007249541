import React, { useEffect, useState } from "react";
import BaseAuth from "./BaseAuth";
import { useLocation } from "react-router";
import { func } from "../../Utilities/logFunc";
import { errors } from "../../Utilities/error";
import { useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { userRequestOTP, forgetPin } from "../../Utilities/controller";
import { useTheme } from "../../ThemeContext";
import { toast } from "react-toastify";
import { logout } from "../../Utilities/logout";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const Otp = () => {
  const globalValues = useTheme();
  const location = useLocation();
  const data = location?.state;
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [timerCount, setTimer] = useState(30);
  const { t } = useTranslation();
  const [loginData, setLoginData] = useState(null);
const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    setLoginData(loginInfo);
  }, []);

  // This function should set the OTP Timer of 30 seconds.
  const setCountDownTimer = () => {
    setTimer(timerCount - 1);
  };

  // This function should show/hide Resend OTP Button Visibility.
  const showHideResendOTP = () => {
    setTimer(30);
  };

  useEffect(() => {
    if (timerCount !== 0) {
      setTimeout(() => {
        setCountDownTimer();
      }, 1000);
    }
  }, [timerCount]);

  const submitHandler = (e) => {
    setIsBusy(true)
    e.preventDefault();
    if (!otp || otp?.length == 0) {
      setError(t('error_enter_code'));
      setIsBusy(false)
    } else if (otp.length != 6) {
      setError(t('error_enter_valid_code'));
      setIsBusy(false)
    } else {
      func(otp);
      callToOTPVerficationAPI(otp);
      // setIsBusy(false)
    }
  };

  // const callToOTPVerficationAPI = async (otp) => {
  //   let loginInfo = localStorage.getItem("loginInfo");
  //   loginInfo = JSON.parse(loginInfo);

  //   if (otp.length >= 6) {
  //     let payload = {
  //       type: "validateOTP",
  //       username: loginInfo?.username,
  //       otp: otp,
  //     };
  //     setLoading(true);
  //     try {
  //       const res = await userRequestOTP(payload);
  //       func("otp response:", res);
  //       if (res?.success) {
  //         globalValues.fetchUserData(res);
  //         localStorage.setItem("token", res?.token);
  //         localStorage.setItem(
  //           "d11_username",
  //           res?.item?.gamerjiName
  //         );
  //         console.log(res?.item?.gamerjiName)
  //         if (res?.item?.isDetailsFilled) window.location.href = "/" + routes.home;
  //         else window.location.href = "/avatar";
  //       }
  //     } catch (error) {
  //       if (
  //         error?.response?.data?.errors?.[0]?.code ==
  //           "middlewares.token_parser.validation.token_invalid" ||
  //         error?.response?.data?.errors?.[0]?.code ==
  //           "middlewares.token_parser.validation.token_expired"
  //       )
  //         logout();
  //       toast.error(error?.response?.data?.errors?.[0]?.msg);
  //       func("error", error);
  //       setError(error?.response?.data?.errors?.[0]?.msg);
  //     }
  //     // showHideResendOTP();
  //     setLoading(false);
  //   }
  // };

  const callToOTPVerficationAPI = async (otp) => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);

    if (otp.length >= 6) {
      let payload = {
        type: "validateOTP",
        username: loginInfo?.username,
        otp: otp,
      };
      setIsBusy(true);
      try {
        eventTracking(events.SIGNUP_RESEND_OTP, {
          MG_CountryCode:"" ,
           MG_MobileNumber:loginInfo?.username,
            MG_UserID:"", 
            MG_Timestamp:""
        });

        const res = await forgetPin(payload);
        console.log("otp response:", res);
        setIsBusy(false);
        if (res?.data?.success) {
          globalValues.fetchUserData(res);
          localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("isDetailsFilled", res?.data?.item?.isDetailsFilled);
          // localStorage.setItem(
          //   "d11_username",
          //   res?.item?.gamerjiName
          // );
          // console.log(res)
          if (res?.data?.item?.isDetailsFilled){
            window.location.href = "/" + routes.setpin;
          } else {
            window.location.href = "/avatar";
          } 
        }
      } catch (error) {
        setIsBusy(false);
        toast.error(error?.response?.data?.errors?.[0]?.msg);
        if (
          error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
        )
          logout();
        
        func("error", error);
        setError(error?.response?.data?.errors?.[0]?.msg);
      }
      showHideResendOTP();
      setLoading(false);
    }
  };

  // This function should call the Resend OTP API
  const callToResendOTPAPI = async (otp) => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    setOtp('')
    setError('')
    let payload = {
      type: "otpRequest",
      username: loginInfo?.username,
      phoneCode: loginInfo?.phoneCode,
      country: loginInfo?.country,
    };

    eventTracking(events.SIGNUP_RESEND_OTP, {
      MG_CountryCode:loginInfo?.country ,
      MG_MobileNumber:loginInfo?.username,
    });
    try {
      const res = await forgetPin(payload);
      func("otp response:", res);
  // console.log(res)

    
      if (res?.data?.success) {
        setTimer(30);
      }
    } catch (error) {
      func("error", error);
     

      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  return (
    <LoginLandingPage>
    <BaseAuth>
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-7 col-9">
          <div class="tab-content auth-content">
            <form class="row justify-content-center d11-codecpro ">
              <h1 class="text-nowrap verification_heading d11-codecpro d11-otp-text text-center lh-1 h2">
              {t("login.enter_verification_code")}
              </h1>
              <h2
                class="form-label text-wrap d1-otp-subText d11-codecpro"
                style={{ marginBottom: "5px" }}
              >
                {t("login.sent_verification_code")}
              </h2>
              <h1 class="Verification_number text-nowrap d11-codecpro mt-1">
                {loginData?.phoneCode} - {loginData?.username}
              </h1>
              <h4
                class="form-label text-nowrap d1-otp-subText d11-codecpro"
                style={{ marginBottom: "0.5rem", marginTop: "1rem" }}
              >
                {t("login.verification_code_received")}
              </h4>
              <div
                class="col-12 justify-content-center align-items-center"
                // style={{ marginTop: "2px" }}
              >
                <input
                  type="number"
                  class="form-control"
                  id="inputCouponCode"
                  placeholder={t("login.enter_verification_code")}
                  value={otp.slice(0, 6)}
                  maxlength="6"
                  onChange={(e) => setOtp((prev) => e.target.value)}
                />
                {error && <p className="error  mb-0">{error}</p>}
              </div>
              <div class="d-grid" style={{ marginTop: "10px" }}>
                <button
                  class="btn btn-primary btn-arrow mb-3"
                  disabled={isBusy}
                  onClick={(e) => submitHandler(e)}
                >
                  {t("login.submit")}
                </button>
                <div class="links">
                  <h5 className="d11-text-center">
                    <span class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend">
                      {" "}
                      {t("login.didnt_receive_code")}
                    </span>
                  </h5>
                </div>

                <div class="links">
                  <h5 className="d11-text-center">
                    {timerCount == 0 ? (
                      <span
                        class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                        style={{
                          color: "var(--accent-color)",
                          cursor: "pointer",
                        }}
                  // disabled={isBusy}

                        onClick={(e) => callToResendOTPAPI(e)}
                      >
                        {t("login.resend")}
                      </span>
                    ) : null}
                  </h5>
                  <h5 className="d11-text-center">
                    {timerCount != 0 ? (
                      <span
                        class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend"
                        // onClick={(e) => callToResendOTPAPI(e)}
                        disabled={isBusy}

                      >
                        {t("login.request_one", { n: timerCount })}
                      </span>
                    ) : null}
                  </h5>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BaseAuth>
    </LoginLandingPage>
  );
};

export default Otp;
